<template>
  <TModal
    title="Create order"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <SButtonCreateOrder
        color="primary"
        @click="create"
        :options="{
          disabled: loading || !productItem.expected_delivery,
        }"
      />
    </template>
    <CRow>
      <CCol col="12" lg="6">
        <SCardProductItems :item="product">
          <template #append-content>
            <CRow>
              <CBadge color="info" class="d-flex">
                <TMessageNumber :value="quantityItems" />
                <TMessage class="ml-1" content="Piece" />
                <span class="mx-1">/</span> <TMessage content="Box" />
              </CBadge>
            </CRow>
          </template>
        </SCardProductItems>
      </CCol>
      <CCol col="12" lg="6">
        <CRow>
          <CCol col="12" lg="6">
            <TInputDateTime
              label="Desired delivery"
              :value.sync="productItem.expected_delivery"
              @update:value="productItem.expected_delivery = $event"
            />
          </CCol>
          <CCol col="12" lg="6">
            <TInputDateTime
              class="mb-2"
              label="Payment due date"
              :value.sync="data.payment_due_date"
              @update:value="data.payment_due_date = $event"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow class="mb-2">
      <CCol col="12" lg="6" class="mb-3">
        <CRow class="mt-3">
          <CCol col="12" md="6">
            <TInputMoney
              label="Price"
              class="mb-3"
              :value.sync="productItem.price"
              :currency="data.purchase_cost_currency_id"
            />
          </CCol>
          <CCol col="12" md="6">
            <TMessage
              content="Use tax-inclusive price"
              class="mb-2 pb-1"
              bold
            />
            <TSwitch :checked="priceHasTax" @change="priceHasTax = $event" />
          </CCol>
        </CRow>
        <TInputNumber
          class="mb-3"
          label="Quantity"
          :append="$t('Piece')"
          :value.sync="productItem.quantity"
          :maskOptions="{ allowDecimal: false }"
        />
        <CRow class="mt-3">
          <CCol col="12" md="6">
            <TInputNumber
              class="mb-3"
              label="Tax percent"
              append="%"
              :value.sync="productItem.tax_percent"
              :maskOptions="{ integerLimit: 2, allowDecimal: false }"
            />
          </CCol>
          <CCol col="12" md="6">
            <TInputNumber
              class="mb-3"
              label="Discount tax"
              append="%"
              :value.sync="productItem.discount_tax_per_tax_percent"
              :maskOptions="{ integerLimit: 2, allowDecimal: false }"
            />
          </CCol>
        </CRow>

        <CRow class="">
          <CCol col="12" md="6">
            <SSelectOrganization
              :value.sync="data.sales_organization_id"
              :prepend="[]"
              label="Purchasing company"
              class="mb-3"
            />
          </CCol>
          <CCol col="12" md="6">
            <SSelectOrganization
              :value.sync="data.transport_organization_id"
              :prepend="[]"
              label="Transportation company"
              class="mb-3"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12" md="6">
            <SSelectCurrency
              label="Currency type of cost of goods"
              :value.sync="data.purchase_cost_currency_id"
              :prepend="[]"
              class="mb-3"
            />
          </CCol>
          <CCol col="12" md="6">
            <SSelectCurrency
              label="Currency type of service fee"
              :value.sync="data.service_fee_currency_id"
              :prepend="[]"
              class="mb-3"
            />
          </CCol>
        </CRow>
        <TInputTextarea
          placeholder="Note"
          label="Note"
          class="mb-3"
          @update:value="data.note = $event"
        />
        <div class="mt-3">
          <TMessage content="Auto add contract" class="mb-2 pb-1" bold />
          <TSwitch
            :checked="data.auto_add_contract"
            @change="data.auto_add_contract = $event"
          />
        </div>
      </CCol>
      <CCol col="12" lg="6" class="my-2">
        <SSelectCustomer
          :value.sync="data.customer_id"
          @update:value="data.customer_id = $event"
          label="Customer"
          class="mb-3"
        />
        <SSelectShipmentMethodBox
          @update:value="data.shipment_method_id = $event"
          :value.sync="data.shipment_method_id"
          class="mt-3 mb-4"
        />
        <SSelectShipmentInfo
          :userId="data.customer_id"
          @update:value="shipment_info = { ...$event }"
          class="m3 mb-4"
        />
        <div class="border-primary rounded p-1 px-3">
          <TTableAsForm
            v-if="shipment_info"
            :data="shipment_info"
            :fields="shipmentFields"
            :splitLeft="5"
            class="p-1"
          >
            <template #consignee="{ value }">
              <TMessageText
                :value="value"
                editable
                @change="shipment_info.consignee = $event"
              />
            </template>
            <template #full_address="{ data }">
              <SMessageAddress
                :address="data.address_detail"
                :wardId="data.ward_id"
                :content="data.full_address"
                editable
                @change="updateAddress"
              />
            </template>
            <template #tel="{ value }">
              <TMessageText
                :value="value"
                editable
                @change="shipment_info.tel = $event"
              />
            </template>
            <template #sender_name="{ value }">
              <TMessageText
                :value="value"
                editable
                @change="shipment_info.sender_name = $event"
              />
            </template>
            <template #sender_full_address="{ data }">
              <SMessageAddress
                :address="data.sender_address || ''"
                :wardId="data.sender_ward_id || ''"
                :content="data.sender_full_address || ''"
                editable
                @change="updateSenderAddress"
              />
            </template>
            <template #sender_tel="{ value }">
              <TMessageText
                :value="value"
                editable
                @change="shipment_info.sender_tel = $event"
              />
            </template>
            <template #note="{ value }">
              <TMessageText
                :value="value"
                editable
                @change="shipment_info.note = $event"
              />
            </template>
          </TTableAsForm>
          <TMessageNoData v-else />
        </div>
      </CCol>
      <CCol col="12">
        <div v-if="showError">
          <div class="bg-light p-2 text-danger d-flex mb-2">
            <TMessage content="Number of boxes is" bold class="mb-">
              <template #prefix>
                <CIcon name="cis-info-circle" class="mb-1 mr-2" />
              </template>
              <template #suffix>
                <span class="ml-1 h4 d-inline">{{ numberBox }}.</span>
              </template>
            </TMessage>
            <TMessage
              class="my-auto ml-1"
              content="Please collect enough boxes to save on shipping costs"
              bold
            />
          </div>
        </div>
      </CCol>
      <CCol col="6">
        <div class="border-info p-3 rounded">
          <TMessage content="Packing information" bold size="h3" class="mb-3" />
          <div class="d-flex mb-2">
            <TMessage content="Number of boxes" bold size="h6" />
            <TMessageNumber :value="numberBox" class="ml-auto" />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Weight" bold size="h6" />
            <TMessageNumber :value="weightBox" class="ml-auto" suffix="kg" />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Volume" bold size="h6" />
            <TMessageNumber
              :value="volume"
              :decimalLimit="3"
              class="ml-auto"
              suffix="m3"
            />
          </div>
          <hr />
          <div class="d-flex mb-2">
            <TMessage content="Origin" bold size="h6" />
            <TMessage
              :content="
                product.origin ? product.origin.name : product.origin_id
              "
              noTranslate
              class="ml-auto"
            />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Items in box" bold size="h6" />
            <TMessage :content="quantityItems" noTranslate class="ml-auto" />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Size" bold size="h6" />
            <TMessage
              :title="`${$t('Length')}(cm) x ${$t('Width')}(cm) x ${$t(
                'Height'
              )}(cm)`"
              :content="`${product.length} x ${product.width} x ${product.height} cm`"
              noTranslate
              class="ml-auto"
            />
          </div>
        </div>
      </CCol>
      <CCol col="6">
        <div class="border-info p-3 rounded h-100">
          <TMessage content="Cost of goods" bold size="h3" class="mb-3" />
          <div class="d-flex mb-2">
            <TMessage content="Price" bold size="h6">
              <template #suffix v-if="priceHasTax">
                ({{ $t("Tax included") }})
              </template>
            </TMessage>
            <TMessageMoney
              :amount="productItem.price * productItem.quantity"
              class="ml-auto"
              :currency="data.purchase_cost_currency_id"
            />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Tax" bold size="h6">
              <template #suffix v-if="productItem.tax_percent">
                ({{ productItem.tax_percent }}%)
              </template>
            </TMessage>
            <TMessageMoney
              :amount="tax"
              class="ml-auto"
              :currency="data.purchase_cost_currency_id"
            />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Discount tax" bold size="h6">
              <template #suffix v-if="productItem.discount_tax_per_tax_percent">
                ({{ productItem.discount_tax_per_tax_percent }}%)
              </template>
            </TMessage>
            <TMessageMoney
              :amount="-discount_tax"
              class="ml-auto"
              :currency="data.purchase_cost_currency_id"
            />
          </div>
          <hr />
          <div class="d-flex mb-2">
            <TMessage content="Total amount" bold size="h5" />
            <TMessageMoney
              :amount="totalAmount"
              class="ml-auto h4"
              :currency="data.purchase_cost_currency_id"
            />
          </div>
        </div>
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shipmentFields: [
        { key: "consignee", label: "Consignee" },
        { key: "full_address", label: "Address" },
        { key: "tel", label: "Tel" },
        { key: "sender_name", label: "Sender name" },
        { key: "sender_full_address", label: "Address" },
        { key: "sender_tel", label: "Tel" },
        { key: "note", label: "Note" },
      ],
      productItem: {
        price: 0,
        quantity: 1,
        tax_percent: 0,
        discount_tax_per_tax_percent: 0,
        expected_delivery: null,
      },
      shipment_info: null,
      data: {
        shipment_method_id: "sea",
        note: null,
        customer_id: "",
        purchase_cost_currency_id: "JPY",
        service_fee_currency_id: "VND",
        sales_organization_id: "tomoni-jp",
        transport_organization_id: "tomoni-vn",
        payment_due_date: null,
        auto_add_contract: true,
      },
      priceHasTax: true,
    };
  },
  created() {
    this.$store.dispatch("product.products.apply-query", {
      "filter[suppliers.id]": 308,
      page: this.page,
    });
  },
  destroyed() {
    this.$store.commit("product.products.purge");
  },
  watch: {
    "data.customer_id"() {
      this.shipment_info = null;
    },
    product(value) {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.productItem.product_id = value.id;
      this.productItem.price = value.price;
      this.productItem.tax_percent = value.tax_percent;
    },
  },
  computed: {
    ...mapGetters({
      products: "product.products.list",
      loading: "order.order_wholesale.loading",
    }),
    showError() {
      if (Number.isInteger(this.numberBox)) return false;
      return true;
    },
    page() {
      return Number(this.$route.query["product.products" + "-page"]) || 1;
    },
    weightBox() {
      return (
        (this.product.weight * this.productItem.quantity) / this.quantityItems
      );
    },
    numberBox() {
      return (
        Math.round((this.productItem.quantity / this.quantityItems) * 100) / 100
      );
    },
    quantityItems() {
      return (
        this.product?.suppliers?.find((x) => x.id == 308)?.pivot
          ?.quantity_items || 1
      );
    },
    volume() {
      return (
        ((this.productItem.quantity / this.quantityItems) *
          (this.product.length * this.product.height * this.product.width)) /
        1000000
      );
    },
    totalPrice() {
      return this.productItem.quantity * this.productItem.price;
    },
    totalAmount() {
      return this.totalPrice + this.tax - this.discount_tax || 1;
    },
    tax() {
      if (this.priceHasTax) return 0;
      return (this.productItem.tax_percent * this.totalPrice) / 100;
    },
    taxInclusivePricePerPiece() {
      if (this.priceHasTax)
        return (
          (this.productItem.price / (100 + this.productItem.tax_percent)) * 100
        );
      return 0;
    },
    discount_tax() {
      if (this.priceHasTax)
        return (
          (this.totalPrice * this.productItem.discount_tax_per_tax_percent) /
          (100 + this.productItem.tax_percent)
        );
      return (
        (this.productItem.discount_tax_per_tax_percent * this.totalPrice) / 100
      );
    },
  },
  methods: {
    create() {
      const productItem = {
        ...this.productItem,
        price: this.priceHasTax
          ? this.taxInclusivePricePerPiece
          : this.productItem.price,
      };
      const item = this.lodash.mapKeys(productItem, (value, key) => {
        return `items[0][${key}]`;
      });
      let data = {
        "shipment_info[consignee]": this.shipment_info?.consignee,
        "shipment_info[address]": this.shipment_info?.address_detail,
        "shipment_info[tel]": this.shipment_info?.tel,
        "shipment_info[ward_id]": this.shipment_info?.ward_id,
        "shipment_info[note]": this.shipment_info?.note,
        "shipment_info[sender_name]": this.shipment_info?.sender_name,
        "shipment_info[sender_tel]": this.shipment_info?.sender_tel,
        "shipment_info[sender_address]": this.shipment_info?.sender_address,
        "shipment_info[sender_ward_id]": this.shipment_info?.sender_ward_id,
        ...this.data,
        auto_add_contract: this.data.auto_add_contract ? "1" : "0",
        ...item,
      };
      const dataClean = {
        ...this.lodash.pickBy(data, this.lodash.identity),
        shipment_method_id: this.data.shipment_method_id,
      };

      this.$store
        .dispatch("order.order_wholesale.create", dataClean)
        .then((data) => {
          this.$router.push({ path: `${data.id}` });
        });
    },
    updateAddress(data, full_address) {
      this.shipment_info.ward_id = data.ward_id;
      this.shipment_info.address_detail = data.address;
      this.shipment_info.full_address = data.address + ", " + full_address;
    },
    updateSenderAddress(data, full_address) {
      this.shipment_info.sender_ward_id = data.ward_id;
      this.shipment_info.sender_address = data.address;
      this.shipment_info.sender_full_address =
        data.address + ", " + full_address;
    },
  },
};
</script>
