<template>
  <div>
    <div class="w-100">
      <CCard class="mt-3" @keypress.enter="search">
        <CCardHeader> <TMessage content="Search" bold /> </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="6" md="3" class="mb-3">
              <TInputText
                label="Product Id"
                placeholder="Jancode"
                :value.sync="filter.id"
                :inputOptions="{ lazy: true }"
                @input="filter.id = $event"
              />
            </CCol>
            <CCol col="12" sm="6" md="3" class="mb-3">
              <TInputText
                label="Product name"
                placeholder="Name"
                :value.sync="filter.name"
                :inputOptions="{ lazy: true }"
                @input="filter.name = $event"
              />
            </CCol>
          </CRow>
          <div class="float-right mt-3">
            <SButtonClearFilter
              :disabled="lodash.isEmpty(lodash.pickBy(filter))"
              @click="clearFilter"
            />
            <SButtonSearchFilter
              class="ml-2"
              :disabled="loading"
              @click="search"
            />
          </div>
        </CCardBody>
      </CCard>
    </div>
    <CCard>
      <CCardHeader>
        <TMessage content="Select product" bold />
      </CCardHeader>
      <CCardBody class="d-flex flex-wrap">
        <CardProduct
          class="m-3"
          v-for="product in products"
          :key="product.id"
          :product="product"
          style="width: 160px"
          @click.native="selectProduct(product)"
        />
        <TMessageNoData v-if="!products.length" class="mx-auto my-3" />
        <CreateWholesale :show.sync="showModal" :product="productSelected" />
      </CCardBody>
    </CCard>
    <TPagination store="product.products" class="mt-4" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardProduct from "./CardProduct.vue";
import CreateWholesale from "./CreateWholesale.vue";
export default {
  components: { CardProduct, CreateWholesale },
  data() {
    return {
      filter: {
        id: "",
        name: "",
      },
      showModal: false,
      productSelected: {},
    };
  },
  created() {
    this.$store.dispatch("product.products.apply-query", {
      "filter[suppliers.id]": 308,
      page: this.page,
    });
  },
  computed: {
    ...mapGetters({
      products: "product.products.list",
      loading: "product.products.loading",
    }),
    page() {
      return Number(this.$route.query["product.products" + "-page"]) || 1;
    },
  },
  methods: {
    search() {
      if (this.loading) return;
      this.$router.push({}).catch(() => {});
      let defaultFilter = {
        "filter[suppliers.id]": 308,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });
      this.$store.dispatch(`product.products.apply-query`, query);
    },
    clearFilter() {
      this.filter = {
        id: "",
        name: "",
      };
    },
    selectProduct(product) {
      this.productSelected = product;
      this.showModal = true;
    },
  },
};
</script>
