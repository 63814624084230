<template>
  <div v-if="product" class="cursor-pointer product-item">
    <CImg
      :src="product.image_url"
      thumbnail
      width="auto"
      style="
        object-fit: contain;
        height: 160px;
        min-height: 160px;
        min-width: 160px;
      "
      class="p-0"
    />
    <TMessage
      :content="product.name"
      noTranslate
      bold
      class="my-1 price"
      :truncate="2"
      style="height: 42px"
    />
    <TMessageMoney
      :amount="product.price"
      class="h4 ml-auto"
      color="primary"
      currency="JPY"
    />
    <div class="d-flex flex-wrap">
      <CBadge color="info" class="mr-2 mt-1" v-if="product.origin">
        <TMessage :content="product.origin.name" noTranslate />
      </CBadge>
      <CBadge color="info" class="d-flex mt-1" v-if="supplier.pivot">
        <TMessageNumber :value="supplier.pivot.quantity_items" />
        <TMessage class="ml-1" content="Piece" />
        <span class="mx-1">/</span> <TMessage content="Box" />
      </CBadge>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    supplier() {
      return this.product.suppliers.find((x) => x.id == 308);
    },
  },
};
</script>

<style scoped>
.product-item:hover .price {
  color: #39f;
}
</style>
